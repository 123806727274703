import Checkbox from '@/components/Checkbox';
import InputError from '@/components/InputError';
import InputLabel from '@/components/InputLabel';
import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout';
import GuestLayout from '@/layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const submit = (e) => {
        e.preventDefault();

        post(route('login'), {
            onFinish: () => reset('password'),
        });
    };

    return (
        <AuthenticatedLayout>
            <Head title="Log in" />

            {status && (
                <div className="mb-4 text-sm font-medium text-green-600">
                    {status}
                </div>
            )}
            <main>
                <section className="i pg fh rm ki xn vq gj qp gr hj rp hr">
                    <img src="/images/shape-06.svg" alt="Shape" className="h j k" />
                    <img src="/images/shape-03.svg" alt="Shape" className="h l m" />
                    <img src="/images/shape-17.svg" alt="Shape" className="h n o" />
                    <img src="/images/shape-18.svg" alt="Shape" className="h p q" />
                    <div className="animate_top bb af i va sg hh sm vk xm yi _n jp hi ao kp" >
                        <span className="rc h r s zd/2 od zg gh"></span>
                        <span className="rc h r q zd/2 od xg mh"></span>

                        <div className="rj">
                            <h2 className="ek ck kk wm xb">Sign in to your Account</h2>
                            <p>Access your dashboard, track events, and manage your profile.</p>

                            {/* <h3 className="hk yj kk wm ob mc">Sign in with Social Media</h3>
                            <ul className="tc wf xf mg ec">
                                <li>
                                    <a
                                        className="tc wf xf be dd di sg _g ch qm ml il bm rl/40 ym/40"
                                        href="#"
                                    >
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_50_914)">
                                                <path
                                                    d="M22.0001 11.2439C22.0134 10.4877 21.9338 9.73268 21.7629 8.99512H11.2246V13.0773H17.4105C17.2933 13.793 17.0296 14.4782 16.6352 15.0915C16.2409 15.7048 15.724 16.2336 15.1158 16.6461L15.0942 16.7828L18.4264 19.3125L18.6571 19.3351C20.7772 17.4162 21.9997 14.5928 21.9997 11.2439"
                                                    fill="#4285F4"
                                                />
                                                <path
                                                    d="M11.2245 22C14.255 22 16.7992 21.0222 18.6577 19.3355L15.1156 16.6465C14.1679 17.2945 12.8958 17.7467 11.2245 17.7467C9.80508 17.7386 8.42433 17.2926 7.27814 16.4721C6.13195 15.6516 5.27851 14.4982 4.83892 13.1755L4.70737 13.1865L1.24255 15.8143L1.19727 15.9377C2.13043 17.7603 3.56252 19.2925 5.33341 20.3631C7.10429 21.4338 9.14416 22.0005 11.2249 22"
                                                    fill="#34A853"
                                                />
                                                <path
                                                    d="M4.83889 13.1756C4.59338 12.4754 4.46669 11.7405 4.46388 11.0001C4.4684 10.2609 4.59041 9.52697 4.82552 8.82462L4.81927 8.6788L1.31196 6.00879L1.19724 6.06226C0.410039 7.59392 0 9.28503 0 11C0 12.715 0.410039 14.4061 1.19724 15.9377L4.83889 13.1756"
                                                    fill="#FBBC05"
                                                />
                                                <path
                                                    d="M11.2249 4.25337C12.8333 4.22889 14.3888 4.8159 15.565 5.89121L18.7329 2.86003C16.7011 0.992106 14.0106 -0.0328008 11.2249 3.27798e-05C9.14418 -0.000452376 7.10433 0.566279 5.33345 1.63686C3.56256 2.70743 2.13046 4.23965 1.19727 6.06218L4.82684 8.82455C5.27077 7.50213 6.12703 6.34962 7.27491 5.5295C8.4228 4.70938 9.80439 4.26302 11.2249 4.25337"
                                                    fill="#EB4335"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_50_914">
                                                    <rect width="22" height="22" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="tc wf xf be dd di sg _g ch qm ml il bm rl/40 ym/40"
                                        href="#"
                                    >
                                        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M22.1506 2.11765C21.3353 2.48824 20.4565 2.73176 19.5459 2.84824C20.4776 2.28706 21.1976 1.39765 21.5365 0.328235C20.6576 0.857647 19.6835 1.22824 18.6565 1.44C17.82 0.529412 16.6447 0 15.3106 0C12.8224 0 10.7894 2.03294 10.7894 4.54235C10.7894 4.90235 10.8318 5.25177 10.9059 5.58C7.13647 5.38941 3.78 3.57882 1.54588 0.836471C1.15412 1.50353 0.931765 2.28706 0.931765 3.11294C0.931765 4.69059 1.72588 6.08824 2.95412 6.88235C2.20235 6.88235 1.50353 6.67059 0.889412 6.35294C0.889412 6.35294 0.889412 6.35294 0.889412 6.38471C0.889412 8.58706 2.45647 10.4294 4.53176 10.8424C4.15059 10.9482 3.74823 11.0012 3.33529 11.0012C3.04941 11.0012 2.76353 10.9694 2.48823 10.9165C3.06 12.7059 4.72235 14.04 6.72353 14.0718C5.17765 15.3 3.21882 16.02 1.08 16.02C0.72 16.02 0.36 15.9988 0 15.9565C2.01176 17.2482 4.4047 18 6.96706 18C15.3106 18 19.8953 11.0753 19.8953 5.07176C19.8953 4.87059 19.8953 4.68 19.8847 4.47882C20.7741 3.84353 21.5365 3.03882 22.1506 2.11765Z"
                                                fill="#00AFED"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="tc wf xf be dd di sg _g ch qm ml il bm rl/40 ym/40"
                                        href="#"
                                    >
                                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.7 12.65H10.45L11.55 8.25H7.7V6.05C7.7 4.917 7.7 3.85 9.9 3.85H11.55V0.154C11.1914 0.1067 9.8373 0 8.4073 0C5.4208 0 3.3 1.8227 3.3 5.17V8.25H0V12.65H3.3V22H7.7V12.65Z"
                                                fill="#1877F2"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="tc wf xf be dd di sg _g ch qm ml il bm rl/40 ym/40"
                                        href="#"
                                    >
                                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.2731 0C9.7927 0 8.32679 0.291587 6.95907 0.858113C5.59136 1.42464 4.34862 2.25501 3.30182 3.30181C1.1877 5.41593 0 8.28329 0 11.2731C0 16.2558 3.23538 20.4832 7.7108 21.9825C8.27446 22.0727 8.45483 21.7233 8.45483 21.4189C8.45483 21.1596 8.45483 20.4494 8.45483 19.5137C5.33218 20.1901 4.66706 18.0031 4.66706 18.0031C4.1485 16.6955 3.41575 16.346 3.41575 16.346C2.3899 15.6471 3.49466 15.6696 3.49466 15.6696C4.62197 15.7485 5.21945 16.8307 5.21945 16.8307C6.20021 18.5442 7.85735 18.037 8.49992 17.7664C8.60138 17.0336 8.89448 16.5376 9.21013 16.2558C6.7075 15.974 4.08086 15.0045 4.08086 10.7094C4.08086 9.45813 4.50924 8.45482 5.24199 7.65443C5.12926 7.37261 4.7347 6.2002 5.35472 4.67834C5.35472 4.67834 6.30167 4.37396 8.45483 5.82819C9.3454 5.58018 10.3149 5.45618 11.2731 5.45618C12.2313 5.45618 13.2008 5.58018 14.0914 5.82819C16.2445 4.37396 17.1915 4.67834 17.1915 4.67834C17.8115 6.2002 17.4169 7.37261 17.3042 7.65443C18.037 8.45482 18.4653 9.45813 18.4653 10.7094C18.4653 15.0158 15.8274 15.9627 13.3135 16.2445C13.7194 16.594 14.0914 17.2817 14.0914 18.3301C14.0914 19.8407 14.0914 21.0581 14.0914 21.4189C14.0914 21.7233 14.2717 22.084 14.8467 21.9825C19.3221 20.4719 22.5462 16.2558 22.5462 11.2731C22.5462 9.79269 22.2546 8.32678 21.6881 6.95907C21.1216 5.59135 20.2912 4.34862 19.2444 3.30181C18.1976 2.25501 16.9549 1.42464 15.5871 0.858113C14.2194 0.291587 12.7535 0 11.2731 0Z"
                                                fill="#79808A"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            </ul> */}

                            {/* <span className="i rc sj hk xj">
                                <span className="rc h s z/2 nd oe rh tm"></span>
                                <span className="rc h q z/2 nd oe rh tm"></span>

                                Or, sign in with your email
                            </span> */}
                        </div>
                        <form className="sb" onSubmit={submit}>
                            <div>
                                <InputLabel className="rc kk wm vb" htmlhtmlFor="email" value="Email" />
                                <TextInput
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    className="vd hh rg zk _g ch hm dm fm pl/50 xi mi sm xm pm dn/40"
                                    autoComplete="username"
                                    isFocused={true}
                                    onChange={(e) => setData('email', e.target.value)}
                                />
                                <InputError message={errors.email} className="mt-2" />
                            </div>

                            <div className="mt-4">
                                <InputLabel className="rc kk wm vb" htmlhtmlFor="password" value="Password" />
                                <TextInput
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={data.password}
                                    className="vd hh rg zk _g ch hm dm fm pl/50 xi mi sm xm pm dn/40"
                                    autoComplete="current-password"
                                    onChange={(e) => setData('password', e.target.value)}
                                />

                                <InputError message={errors.password} className="mt-2" />
                            </div>

                            <div className="mt-4 block">
                                <label className="flex items-center">
                                    <Checkbox
                                        name="remember"
                                        checked={data.remember}
                                        onChange={(e) =>
                                            setData('remember', e.target.checked)
                                        }
                                    />
                                    <span className="ms-2 text-sm text-gray-600">
                                        Remember me
                                    </span>
                                </label>
                            </div>
                            <div className="mt-4 flex items-center justify-end">
                                {/* {canResetPassword && (
                                    <Link
                                        href={route('password.request')}
                                        className="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Forgot your password?
                                    </Link>
                                )} */}

                                {/* <PrimaryButton className="vd rj ek rc rg gh lk ml il _l gi hi" disabled={processing}>
                                    Log in
                                </PrimaryButton>
                                <p className="sj hk xj rj ob">
                                    Don't have an account?
                                    <Link className="mk" 
                                      href={route('app_web_frontend_register_new')}
                                    > Sign Up </Link>
                                </p> */}
                            </div>
                            <button type='submit' disabled={processing} className="vd rj ek rc rg gh lk ml il _l gi hi">
                                Sign In
                            </button>
                            <p className="sj hk xj rj ob">
                                Don't have an account?
                                <a className="mk" href="signup.html"> Sign Up </a>
                            </p>
                        </form>
                    </div>
                </section>
            </main>

        </AuthenticatedLayout>
    );
}
